import React, { useEffect, useRef, useState, useCallback } from 'react'
import CreateProductsStyle from './CreateProductsStyle'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import api, { customFuncsApi, customPhotoUploadApi } from '../../../api'
import { get } from 'lodash'
import { BsSearch } from 'react-icons/bs'
import {
	ErrorModal,
	SuccessModal,
	WarningModal,
} from '../../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import ChangeDate from './ChangeDate'
import { main } from '../../../store/slices'
import Layout from '../../../components/Layout'
import moment from 'moment'
import { roundCurrency } from '../../../utils'
import customMainApi from '../../../api/index'
import CreateUserModal from '../../../components/Modal/InstallmentCreateUser'
import CreateIcloudModal from '../../../components/Modal/CreateIcloud'
import _ from 'lodash'
import {
	Input,
	message,
	Button as AntButton,
	AutoComplete,
	Spin,
	Select,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { EyeOutlined } from '@ant-design/icons'
import UpdateUser from '../../ViewItem/UpdateUser'

const CreateProducts = () => {
	const { setDate } = main.actions
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const changeDateRef = useRef()
	const errorRef = useRef()
	const infoNotEndedRef = useRef()
	const successModalRef = useRef()
	const succesCreateUserRef = useRef()
	const createUserRef = useRef()
	const updateUserRef = useRef()
	const createIcloud = useRef()

	const [icloudData, setIcloudData] = useState([])
	const [customerData, setCustomerData] = useState([])
	const [productNameData, setProductNameData] = useState([])
	const [seriaNumberData, setSeriaNumberData] = useState([])
	const [tableData, setTableData] = useState([])
	const [simpleMonthData, setsimpleMonthData] = useState([])
	const [vipMonthData, setVipMonthData] = useState([])
	const [garantData, setGarantData] = useState([])
	const [U_DaftarBet, setU_DaftarBet] = useState('')
	const [garant, setGarant] = useState('')
	const [garantCardCode, setGarantCardCode] = useState('')
	const [price, setPrice] = useState('')
	const [status] = useState('ishlayapti')

	const [icloud, setIcloud] = useState('')
	const [icloudCode, setIcloudCode] = useState('')

	const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))

	const [color, setColor] = useState('')
	const [month, setMonth] = useState('')
	const [monthB2B, setMonthB2B] = useState('')
	const [customer, setCustomer] = useState('')
	const [condition, setCondition] = useState('')
	const [productName, setProductName] = useState('')
	const [seriaNumber, setSeriaNumber] = useState('')
	const [currentPrice, setCurrentPrice] = useState('')
	const [userCardCode, setUserCardCode] = useState('')
	const [seriaNumberMain, setSeriaNumberMain] = useState('')
	const [systemSerialNumber, setSystemSerialNumber] = useState('')
	const [minusedMoney, setMinusedMoney] = useState(0)
	const [productCode, setProductCode] = useState(0)
	const [selectProductName, setSelectProductName] = useState('')
	const [dateIndex, setDateIndex] = useState(0)
	const [viewData, setViewData] = useState({})
	const [isCash, setIsCash] = useState(false)
	const [firstPayingMoney, setFirstPayingMoney] = useState(0)
	const [isCalculated, setIsCalculated] = useState(false)
	const [isMainLoading, setIsMainLoading] = useState(false)
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [loadingUser, setLoadingUser] = useState(false)
	const [loadingItem, setItemLoading] = useState(false)
	const [loadingGarantUser, setLoadingGarantUser] = useState(false)
	const [loadingIcloud, setLoadingIcloud] = useState(false)
	const [comment, setComment] = useState('')
	const [comment2, setComment2] = useState('')
	const [costPrice, setCostPrice] = useState('')
	const [boxPlace, setBoxPlace] = useState('')
	const [userFields2, setUserFields2] = useState([])
	const [userFields3, setUserFields3] = useState([])
	const [security, setSecurity] = useState([])
	const [bateryLife, setBateryLife] = useState('')
	const [supplierName, setSupplierName] = useState('')
	const [bateryLifeInPurchase, setBateryLifeInPurchase] = useState('')
	const [itemRegion, setItemRegion] = useState('')
	const [userFields, setUserFields] = useState([])
	const [warehouses, setWarehouses] = useState([])
	const [itemsPage, setItemsPage] = useState(0)
	const [hasMoreItems, setHasMoreItems] = useState(false)
	const [toWarehouse, setToWarehouse] = useState(
		get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
			? get(getMe, 'U_Warehouse', '')
			: '',
	)
	const [warehouseLoading, setWarehouseLoading] = useState(false)
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])

	useEffect(() => {
		getSimpleMonth()
		getVIPMonth()
		userFieldsFn()
		getWarehouses()
		userFieldsOINVFn()
		getIclouds()
		userFieldsFnIcloud()
	}, [])

	useEffect(() => {
		getItemsLastPurchaseInfo(productCode, systemSerialNumber)
	}, [productCode, systemSerialNumber])

	const [inputValue, setInputValue] = useState('')

	const handleScroll = useCallback(
		_.debounce((e) => {
			const dropdown = e.target
			if (
				dropdown.scrollTop + dropdown.clientHeight >=
					dropdown.scrollHeight - 20 &&
				hasMoreItems &&
				!loadingItem
			) {
				setItemsPage((prev) => prev + 20)
			}
		}, 500),
		[hasMoreItems, loadingItem, setItemsPage],
	)

	const formatProductData = (item) => {
		const itemName = get(item, 'ItemName', '')
		const color =
			get(item, 'U_Color', '') !== ''
				? userFields
						?.find((userField) => userField.Name === 'Color')
						?.ValidValuesMD.find(
							(value) => get(item, 'U_Color', '') === value.Value,
						)?.Description || ''
				: ''
		const memory =
			get(item, 'U_Memory', '') !== ''
				? userFields
						?.find((userField) => userField.Name === 'Memory')
						?.ValidValuesMD.find(
							(value) => get(item, 'U_Memory', '') === value.Value,
						)?.Description || ''
				: ''
		const condition = t(get(item, 'U_Condition', '')) ?? ''

		return [itemName, color, memory, condition].filter(Boolean).join(' ')
	}

	useEffect(() => {
		searchProduct(inputValue, itemsPage, toWarehouse)
	}, [inputValue, itemsPage, toWarehouse])

	const getItemsLastPurchaseInfo = (productCode, systemSerialNumber) => {
		if (!productCode || !systemSerialNumber) return
		setWarehouseLoading(true)
		customFuncsApi
			.get(
				`/xsSql/getLastPurchaseOfSerial?ItemCode=${productCode}&SysSerial=${systemSerialNumber}`,
			)
			.then((res) => {
				const data = res.data
				console.log('Data = ', res.data)
				setSupplierName(get(data, 'CardName', ''))
				setBateryLifeInPurchase(get(data, 'U_Percent', ''))
				setItemRegion(get(data, 'U_Region_Item', ''))
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const getSimpleMonth = () => {
		api
			.get('U_INSTCONFBASIC')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setsimpleMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const userFieldsOINVFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields2(JSON.parse(res.data).value)
			})
	}

	const userFieldsFnIcloud = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq '@Security'",
				},
			})
			.then((res) => {
				setUserFields3(JSON.parse(res.data).value)
			})
	}

	const getIclouds = () => {
		customMainApi
			.get('Security', {
				params: {
					$select:
						'Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount',
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setSecurity(JSON.parse(res.data).value)
			})
	}

	const getVIPMonth = () => {
		api
			.get('U_INSTCONFVIP')
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setVipMonthData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
	}

	const searchUser = (a = '') => {
		setCustomer(a)
		const formattedName = a.includes("'") ? a.replace(/'/g, "''") : a
		setLoadingUser(true)
		api
			.get(
				`BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${formattedName}') or contains(U_PS, '${formattedName}') or contains(Phone1, '${formattedName}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setCustomerData(resData)
				const selectedCustomer = resData.find(
					(v) => get(v, 'CardName', '') === a,
				)
				if (selectedCustomer) {
					setUserCardCode(get(selectedCustomer, 'CardCode', ''))
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setLoadingUser(false)
			})
	}

	const searchUserGarant = (a = '', who) => {
		setLoadingGarantUser(true)
		who === 'garant' ? setGarant(a) : setCustomer(a)
		const formattedName = a.includes("'") ? a.replace(/'/g, "''") : a
		api
			.get(
				`BusinessPartners?$select=CardCode,FreeText,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and Frozen eq 'tNO' and (contains(CardName, '${formattedName}') or contains(U_PS, '${formattedName}') or contains(Phone1, '${formattedName}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				who === 'garant' ? setGarantData(resData) : setCustomerData(resData)

				if (a.includes(',')) {
					who === 'garant'
						? setGarantCardCode(a.split(',')[1])
						: setUserCardCode(a.split(',')[1])
				}
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setLoadingGarantUser(false)
			})
	}

	const searchIcloud = (a = '') => {
		setLoadingIcloud(true)
		setIcloud(a)
		api
			.get(
				`Security?$select=Code,Name,U_Whichtelephone,U_Password,U_Status,U_TypeAccount&$orderby=Code desc&$filter=U_Status eq 'bosh' and (contains(Name, '${a}') or contains(Code, '${a}'))`,
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				setIcloudData(resData)
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							JSON.parse(err.response.data),
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setLoadingIcloud(false)
			})
	}

	const searchProduct = (name = '', itemsPage = 0, toWarehouse) => {
		const a = name
		setProductName(a)
		setItemLoading(true)
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'&` : `WhsCode='${toWarehouse}'&`}ItemName=${a}&isOnHand=true&$skip=${itemsPage}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000',
					},
				},
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setHasMoreItems(resData.length === 20)
				setProductNameData(resData)
			})
			.catch((err) => {
				if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							err.response.data,
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
			.finally(() => {
				setItemLoading(false)
			})
	}

	const debouncedSearchUser = _.debounce((value) => searchUser(value), 1000)

	const debouncedSearchProduct = _.debounce(
		(value) => setInputValue(value),
		1000,
	)

	const debouncedSearchGarant = _.debounce(
		(value) => searchUserGarant(value),
		1000,
	)

	const debouncedSearchIcloud = _.debounce((value) => searchIcloud(value), 1000)

	const getSameSerialNumber = (num) => {
		customFuncsApi
			.get(
				`/xsSql/getItemSerialsByWhsCode?ItemCode='${num}'${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : `&WhsCode='${toWarehouse}'`}`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000000',
					},
				},
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setSeriaNumberData(resData)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить серийный номер. Повторите попытку.',
					),
				)
			})
	}

	const getOtherInformations = (v) => {
		const num = Number(v.split(') ')[0]) - 1
		setSeriaNumber(v)
		const code = v.split(') ')[1] || ''
		seriaNumberData.forEach((el, index) => {
			const DistNumber = get(el, 'IntrSerial', '')
			if (DistNumber === code) {
				setSystemSerialNumber(get(el, 'SysNumber', ''))
				setSeriaNumberMain(get(el, 'IntrSerial', ''))
			}
		})
		const dataO = seriaNumberData[num]

		setColor(get(dataO, 'U_Color', ''))
		setCondition(get(dataO, 'U_Condition', ''))
		setCurrentPrice(get(dataO, 'CostTotal', ''))
		setCostPrice(get(dataO, 'CostTotal', ''))
	}

	const getOtherInformationsfromSerialNumber = () => {
		customFuncsApi
			.get(
				`/xsSql/getItemsByWarehouse?${get(getMe, 'Department2.Name', '') !== 'Boshqaruv' ? `WhsCode='${get(getMe, 'U_Warehouse', '')}'&` : `WhsCode='${toWarehouse}'&`}IntrSerial='${seriaNumber}'`,
			)
			.then((res) => {
				const resData = get(res.data, 'value', [])
				setProductNameData(resData)
			})
			.catch((err) => {
				if (JSON.stringify(err, null, 2).status === 401) {
					navigate('/login')
				} else {
					errorRef.current?.open(
						get(
							err.response.data,
							'error.message',
							'Не удалось получить данные, попробуйте еще раз.',
						),
					)
				}
			})
	}

	const addTable = () => {
		if (
			customer.length > 0 &&
			productName.length > 0 &&
			seriaNumber.length > 0 &&
			(month.length > 0 || monthB2B.length > 0)
		) {
			let monthNumber = monthB2B.length > 0 ? monthB2B : month
			monthNumber = Number(monthNumber.split(' oy')[0]) + 1

			setTableData([])

			for (let i = 0; i < monthNumber; i++) {
				let nextMonth = moment().clone().add(i, 'months')
				setTableData((pr) => [
					...pr,
					{
						date: nextMonth.format('YYYY-MM-DD'),
						price: 0,
						price2: 0,
					},
				])
			}
			setIsCalculated(true)
		} else {
			infoNotEndedRef.current?.open()
		}
	}

	useEffect(() => {
		if (month.length > 0 || monthB2B.length > 0) {
			addTable()
		}
	}, [month, monthB2B])

	const ditributeMoney = useCallback(
		(money1, costPrice1) => {
			const money = Number(money1)
			const costPrice = Number(costPrice1)
			setFirstPayingMoney(money)
			if (money >= 0) {
				let protsent = monthB2B.length > 0 ? monthB2B : month
				let splitProsent = protsent.split(' oy')

				let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData

				const foundItem = whichType.find(
					(item) => item.U_Months == splitProsent[0],
				)

				if (foundItem && foundItem.U_Percent !== undefined) {
					protsent = 1 + Number(foundItem.U_Percent) / 100
				} else {
					protsent = 1
				}

				let sameNumber = roundCurrency(
					(Number(costPrice) - Number(money)) * protsent,
				)

				let monthMoney = roundCurrency(+sameNumber / (tableData.length - 1))

				setMinusedMoney(Number(money))
				let b = roundCurrency(+sameNumber + Number(money))
				setPrice(b)

				if (Number(money) >= 0) {
					for (let i = 0; i < tableData.length; i++) {
						let aa = [...tableData]
						if (i !== 0) {
							aa[i].price = monthMoney
						} else {
							aa[0].price = money
						}
						setTableData(aa)
					}
				}
			}
		},
		[
			monthB2B,
			month,
			vipMonthData,
			simpleMonthData,
			costPrice,
			price,
			tableData,
		],
	)

	const getWarehouses = () => {
		setWarehouseLoading(true)
		customMainApi
			.get('Warehouses?$select=WarehouseCode,WarehouseName')
			.then((res) => {
				setWarehouses(JSON.parse(res.data).value)
			})
			.catch((err) => {
				if (err)
					errorRef.current?.open(
						JSON.stringify(err.response.data?.details?.message),
					)
			})
			.finally(() => {
				setWarehouseLoading(false)
			})
	}

	const checkU_WHSCode = () => {
		if (get(getMe, 'Department2.Name', '') !== 'Boshqaruv') {
			if (get(getMe, 'U_Warehouse', null) === null) {
				alert(t('noWhs'))
			} else {
				createOrder()
			}
		} else {
			createOrder()
		}
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	const addNewRow = () => {
		setImageList([...imageList, { file: null, path: '', imgLoading: false }])
	}

	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}

	const createOrder = () => {
		let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData
		const photoId = imageList
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		if (!isCash) {
			let protsent = monthB2B.length > 0 ? monthB2B : month || ''
			let splitProsent = protsent.split(' oy')

			protsent =
				1 +
				Number(
					whichType.find((item) => item?.U_Months == splitProsent[0])
						?.U_Percent,
				) /
					100
		}
		setIsMainLoading(true)

		const dataT = tableData
			.map((v, idx) => {
				const totalAmount =
					idx === 0 ? parseFloat(firstPayingMoney) : parseFloat(v.price)

				if (Number(totalAmount) === 0) {
					return null
				}

				return {
					Total: totalAmount,
					DueDate: v.date,
				}
			})
			.filter(Boolean)

		let body = {
			CardCode: userCardCode,
			DocDate: moment(docDate).format('YYYY-MM-DD'),
			U_Guarantor: garantCardCode,
			U_DaftarBet: U_DaftarBet,
			U_Security: icloudCode,
			U_CostPrice: costPrice,
			U_BoxPlace: boxPlace,
			U_PhotoID: photoId,
			SalesPersonCode: getMe.SalesPersonCode || -1,
			Comments: comment,
			U_FirstPayment: parseFloat(firstPayingMoney),
			U_COMMENT: comment2,
			U_TypeInvoice: 'Nasiya',

			DocumentLines: [
				{
					ItemCode: productCode,
					SerialNumbers: [
						{
							InternalSerialNumber: seriaNumberMain,
							SystemSerialNumber: systemSerialNumber,
						},
					],
					Quantity: 1,
					UnitPrice: parseFloat(price),
					U_BatteryLifeInvoice: bateryLife,

					...(get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
						? { WarehouseCode: get(getMe, 'U_Warehouse', '') }
						: { WarehouseCode: toWarehouse }),
				},
			],
		}

		if (!isCash) Object.assign(body, { DocumentInstallments: dataT })

		customFuncsApi
			.post(`addInvoice`, body)
			.then((res) => {
				const resData = res.data
				setViewData({
					CardName: get(resData, 'CardName', ''),
					DocEntry: get(resData, 'DocEntry', 0),
					CardCode: get(resData, 'CardCode', 0),
					ItemDescription: productName,
					Comments: get(resData, 'Comments', ''),
					U_Security: icloudCode,
					'Security.U_Status': status,
					Installmnt: 2,
					PhotoId: photoId,
				})
				closeIcloud(icloudCode)
				successModalRef.current?.open('Заказ успешно создан')
			})
			.catch((err) => {
				setIsMainLoading(false)
				errorRef.current?.open(get(err.response.data, 'error.message', ''))
			})
	}

	const closeIcloud = (icloudCode) => {
		if (icloudCode !== null && icloudCode !== '') {
			let url = `Security('${icloudCode}')`
			api
				.patch(url, {
					U_Status: 'ishlayapti',
				})
				.catch((err) => {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				})
		}
	}

	const changeMainPrice = (v) => {
		let mainPrice = Number(v)
		setPrice(v)

		let dataT = mainPrice
		let A = (dataT - minusedMoney) / (Number(tableData.length) - 1)

		for (let i = 0; i < tableData.length; i++) {
			if (i !== 0) {
				let aa = [...tableData]
				aa[i].price = A
				setTableData(aa)
			} else {
				let aa = [...tableData]
				aa[0].price = minusedMoney
				setTableData(aa)
			}
		}
	}

	const changeDate = (v) => {
		let exampleDate = [...tableData]
		let startDate = moment(v).startOf('day').format('YYYY-MM-DD') // Ensure starting date is normalized
		let isEndOfMonth = moment(v).date() === moment(v).endOf('month').date()
		let index = 0
		for (let i = dateIndex; i < exampleDate.length; i++) {
			let nextMonth = moment(startDate).add(index, 'months')
			let selectedDay
			index++
			if (isEndOfMonth) {
				selectedDay = nextMonth.endOf('month').format('YYYY-MM-DD') // Keep it aligned with the end of the month
			} else {
				selectedDay = nextMonth.format('YYYY-MM-DD')
			}

			exampleDate[i].date = selectedDay

			console.log(`Iteration ${i + 1}:`, {
				nextMonth: nextMonth.format('YYYY-MM-DD'),
				selectedDay,
			})
		}

		setTableData(exampleDate)
		changeDateRef.current?.close()
	}

	const openChangeDate = (date, i) => {
		dispatch(setDate(date))
		changeDateRef.current?.open(date)
		setDateIndex(i)
	}

	const handleChangeValues = (v) => {
		let aa = [...tableData]
		let total = 0
		for (let i = 1; i < tableData.length; i++) {
			aa[i].price = v
			total += Number(v)
		}
		total += +firstPayingMoney
		setTableData(aa)
		setPrice(total)
	}

	const viewUser = (v) => {
		updateUserRef.current?.open(v)
	}

	const handleSelectProduct = (val, label) => {
		setItemsPage(0)
		setProductNameData([])
		setProductCode(val)
		setSelectProductName(label.label)
		getSameSerialNumber(val)
		setInputValue('')
		setSeriaNumber('')
	}

	const handleSearchProduct = (val, label) => {
		setItemsPage(0)
		setProductNameData([])
		if (val !== label.value) {
			setSelectProductName(val)
			debouncedSearchProduct(val)
		}
	}

	return (
		<Layout>
			<CreateProductsStyle>
				<div className="container">
					{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ? (
						<div className="mb-5 flex items-start gap-5 sm:gap-10 flex-col sm:flex-row">
							<div>
								<div className={'flex items-center gap-1 w-full '}>
									<select
										id={'from-warehouse'}
										className={`bg-[#FAFBFC] border-[1px] ${
											!toWarehouse ? 'border-red-500' : 'border-[#DFE2E8]'
										} rounded-md p-3 text-[14px] text-[#1E1F1F] w-full sm:w-[315px]`}
										onChange={(e) => setToWarehouse(e.target.value)}
										value={toWarehouse}
										required
									>
										<option value={null}>{t('selectWhs')}</option>
										{warehouses.map((v, i) => (
											<option key={i} value={v.WarehouseCode}>
												{v.WarehouseName}
											</option>
										))}
									</select>
									<ClipLoader size={15} loading={warehouseLoading} />
								</div>
								{!toWarehouse && (
									<p className="text-red-500 text-sm">{t('whsRequired')}</p>
								)}
							</div>
							<div>
								<input
									type="date"
									value={docDate}
									onChange={(e) => {
										setDocDate(e.target.value)
									}}
									className={
										'border-[1px] p-2 border-[#DFE2E9"] rounded-md w-full'
									}
								/>
							</div>
						</div>
					) : (
						''
					)}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-7 lg:grid-cols-3 items-start">
						<div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:col-span-2">
							<div className="flex gap-2">
								<div className="relative w-full">
									<input
										type="text"
										list="client"
										placeholder={t('client')}
										disabled={!toWarehouse}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										defaultValue={customer}
										onChange={(v) => debouncedSearchUser(v.target.value)}
									/>
									{loadingUser && (
										<div className="absolute top-3 right-9">
											<ClipLoader size={20} color="#0A4D68" />
										</div>
									)}

									<datalist id="client">
										{customerData.map((v, i) => (
											<option key={i} value={`${get(v, 'CardName', '')}`} />
										))}
									</datalist>
								</div>

								<div className=" flex gap-2">
									<button
										className="bg-[#0A4D68] text-white px-4 rounded-md"
										onClick={() => createUserRef.current?.open()}
										disabled={!toWarehouse}
									>
										+
									</button>
									{userCardCode ? (
										<Button
											onClick={() => {
												viewUser(userCardCode)
											}}
											className="w-[40px] h-10"
										>
											<EyeOutlined className="w-[40px]" />
										</Button>
									) : null}
								</div>
							</div>
							<div className="relative">
								<input
									type="text"
									list="productName"
									disabled={!toWarehouse}
									placeholder={t('product')}
									className="border-[1px] border-[#DFE2E9] p-2 rounded-md w-full"
									onChange={(v, label) => {
										debouncedSearchProduct(v.target.value)
										const selectedOption = productNameData.find((item) => {
											return formatProductData(item) === v.target.value
										})
										if (selectedOption) {
											const itemCode = get(selectedOption, 'ItemCode', '')
											setProductCode(itemCode)
											getSameSerialNumber(itemCode)
										}
									}}
								/>
								{loadingItem && (
									<div className="absolute top-3 right-9">
										<ClipLoader size={20} color="#0A4D68" />
									</div>
								)}
								<div>
									<datalist id="productName">
										{productNameData.map((v, i) => {
											return (
												<option key={i} value={`${formatProductData(v)}`}>
													{formatProductData(v)}
												</option>
											)
										})}
									</datalist>
								</div>
							</div>

							<div className="flex gap-2">
								<div className="relative w-full">
									<input
										type="text"
										list="garant"
										placeholder="ГАРАНТ"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										disabled={!toWarehouse}
										defaultValue={garant}
										onChange={(v) =>
											debouncedSearchGarant(v.target.value, 'garant')
										}
									/>
									{loadingGarantUser && (
										<div className="absolute top-3 right-9">
											<ClipLoader size={20} color="#0A4D68" />
										</div>
									)}
									<datalist id="garant">
										{garantData.map((v, i) => (
											<option
												key={i}
												value={`${get(v, 'CardName', '')},${get(
													v,
													'CardCode',
													'',
												)},${get(v, 'FreeText', '')}`}
											/>
										))}
									</datalist>
								</div>

								<button
									className="bg-[#0A4D68] text-white px-4 rounded-md"
									onClick={() => createUserRef.current?.open()}
									disabled={!toWarehouse}
								>
									+
								</button>
							</div>
							<div className="flex gap-2">
								<input
									type="text"
									list="seriaNumber"
									placeholder={t('imei')}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									disabled={!toWarehouse}
									value={seriaNumber}
									onChange={(v) => getOtherInformations(v.target.value)}
								/>
								<datalist id="seriaNumber">
									{seriaNumberData
										.filter((v) => get(v, 'IntrSerial', ''))
										.map((v, i) => (
											<option
												key={i}
												value={`${i + 1}) ${get(v, 'IntrSerial', '')}`}
											/>
										))}
								</datalist>
								<button
									className="bg-[#0A4D68] text-white px-4 rounded-md"
									onClick={getOtherInformationsfromSerialNumber}
									disabled={!toWarehouse}
								>
									<BsSearch />
								</button>
							</div>
							<input
								type="text"
								placeholder={t('supplier')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={supplierName}
								disabled={true}
							/>

							<input
								type="text"
								placeholder={t('itemRegion')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={itemRegion}
								disabled={true}
							/>
							<input
								type="text"
								placeholder={t('bateryLifeInPurchase')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={bateryLifeInPurchase}
								disabled={true}
							/>

							<input
								type="text"
								placeholder={t('color')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={
									color !== ''
										? userFields
												?.find((item) => item.Name === 'Color')
												?.ValidValuesMD.find((value) => color === value.Value)
												?.Description || ''
										: ''
								}
								disabled={true}
							/>

							<input
								type="text"
								placeholder={t('condition')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={condition}
								disabled={true}
							/>

							<input
								type="number"
								placeholder={t('price3')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(v) => setCurrentPrice(v.target.value)}
								defaultValue={currentPrice}
								disabled={true}
							/>

							<input
								type="number"
								placeholder={t('price4')}
								// disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={price}
								disabled={true}
								onChange={(v) => changeMainPrice(v.target.value)}
							/>
							<div className="flex  gap-2">
								<input
									type="number"
									placeholder={t('costPrice')}
									disabled={!toWarehouse}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={costPrice}
									onChange={(v) => {
										const val = v.target.value
										setCostPrice(val)
										// changeMainPrice(v.target.value)
										ditributeMoney(firstPayingMoney, val)
									}}
								/>
							</div>

							<input
								placeholder={t('bateryLife')}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								value={bateryLife}
								disabled={!toWarehouse}
								onChange={(v) => setBateryLife(v.target.value)}
							/>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[230px]'
								}
								onChange={(v) => {
									setMonth(v.target.value)
									setMonthB2B('')
									setIsCash(false)
								}}
								value={month}
								disabled={!toWarehouse}
							>
								<option value="" disabled>
									{t('month')}
								</option>
								{simpleMonthData.map((value, idx) => (
									<option key={idx} value={value.U_Months}>
										{`${value.U_Months} oy`}
									</option>
								))}
							</select>
							<input
								type="text"
								placeholder={t('pageNumber')}
								disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								defaultValue={U_DaftarBet}
								onChange={(v) => setU_DaftarBet(v.target.value)}
							/>
							<div className="flex items-center gap-3">
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 mt-2 rounded-md w-full'
									}
									onChange={(event) => setBoxPlace(event.target.value)}
									value={boxPlace}
									placeholder="Коробка"
									disabled={!toWarehouse}
								>
									<option value="" disabled>
										{t('box')}
									</option>
									{userFields2
										?.find((item) => item.Name === 'BoxPlace')
										?.ValidValuesMD.filter((value) => {
											return (
												value.Description !== 'Bor' &&
												value.Description !== 'Yoq'
											)
										})
										.map((value) => (
											<option key={value.Value} value={value.Value}>
												{value.Description}
											</option>
										))}
								</select>
							</div>

							<div className="relative w-full">
								<div className="flex items-center gap-2">
									<div className="w-full">
										<input
											type="text"
											list="icloud"
											placeholder={t('icloud')}
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
											}
											disabled={!toWarehouse}
											// value={icloud}
											// onChange={(v) => debouncedSearchIcloud(v.target.value)}
											onChange={(v) => {
												debouncedSearchIcloud(v.target.value)
												const selectedOption = icloudData.find((item) => {
													const Name = get(item, 'Name', '')
													return Name === v.target.value
												})

												if (selectedOption) {
													const Code = get(selectedOption, 'Code', '')
													setIcloudCode(Code)
												}
											}}
										/>
										{loadingIcloud && (
											<div className="absolute top-3 right-20">
												<ClipLoader size={20} color="#0A4D68" />
											</div>
										)}
										<datalist id="icloud">
											{icloudData.map((v, i) => (
												<option key={i} value={`${get(v, 'Name', '')}`} />
											))}
										</datalist>
									</div>
									<div className="w-[50px]">
										<button
											className="bg-[#0A4D68] text-white px-4 h-[40px] rounded-md"
											onClick={() => createIcloud.current?.open()}
											disabled={!toWarehouse}
										>
											+
										</button>
									</div>
								</div>
							</div>
							<textarea
								placeholder={t('comment')}
								disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
								}
								value={comment}
								onChange={(v) => setComment(v.target.value)}
							/>
							<textarea
								placeholder={t('commentInventory')}
								disabled={!toWarehouse}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full textarea'
								}
								value={comment2}
								onChange={(v) => setComment2(v.target.value)}
							/>
							<div></div>
							<div className="w-full sm:w-[600px]">
								{imageList.map((image, index) => (
									<div
										key={index}
										className="flex flex-col w-full items-start gap-3 mt-5 mb-10"
									>
										<div>
											<p className="font-bold">
												{t('photo')}-{index + 1}
											</p>
										</div>
										<div className="flex flex-col sm:flex-row items-center gap-3">
											<Input
												type="file"
												onChange={(e) => handleFileChange(e, index)}
												className="w-full"
												disabled={!toWarehouse}
											/>
											<AntButton
												onClick={() => uploadImg(index)}
												isLoading={image.imgLoading}
												disabled={!toWarehouse}
												className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
											>
												{t('upload')}
											</AntButton>
											{image.path && (
												<AntButton
													onClick={() => viewImage(index)}
													disabled={!toWarehouse}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('download')}
												</AntButton>
											)}
											{index !== 0 && (
												<AntButton
													onClick={() => deleteRow(index)}
													disabled={!toWarehouse}
													className="h-10 w-full sm:w-[100px] rounded-lg p-2 bg-red-500 text-white"
												>
													{t('delete')}
												</AntButton>
											)}

											{index === imageList.length - 1 && (
												<AntButton
													onClick={addNewRow}
													disabled={!toWarehouse}
													className="h-10 w-full sm:w-[100px] rounded-lg bg-[#0A4D68] text-white"
												>
													{t('add2')}
												</AntButton>
											)}
										</div>
									</div>
								))}
							</div>
						</div>

						{isCash ? (
							''
						) : (
							<div>
								<div className="center mb-4">
									<Button onClick={addTable}>{t('calculation')}</Button>
								</div>

								{isCalculated ? (
									<div className="overflow-y-auto mb-8">
										<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
											<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
												<tr>
													<th scope="col" className="px-6 py-4">
														N
													</th>
													<th scope="col" className="px-6 py-4">
														{t('date')}
													</th>
													<th scope="col" className="px-6 py-4">
														{t('payment')}
													</th>
													<th scope="col" className="px-6 py-4">
														{t('paid2')}
													</th>
												</tr>
											</thead>
											<tbody>
												{tableData.map((v, i) => {
													return (
														<tr
															className="bg-white border-b  hover:bg-gray-50 "
															key={i}
														>
															<td className="px-6 py-4 font-medium text-gray-900 ">
																{i}
															</td>
															<td
																onClick={() => openChangeDate(v.date, i)}
																className="px-6 py-4 whitespace-nowrap"
															>
																{v.date}
															</td>
															<td className="px-6 py-4">
																{i === 0 && (
																	<input
																		type="number"
																		className={
																			'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
																		}
																		style={{ minWidth: 100 }}
																		onChange={(v) => {
																			ditributeMoney(v.target.value, costPrice)
																			setFirstPayingMoney(v.target.value)
																		}}
																	/>
																)}
																{i === 1 && (
																	<input
																		type="number"
																		className={
																			'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
																		}
																		style={{ minWidth: 100 }}
																		value={v.price}
																		onChange={(v) =>
																			handleChangeValues(v.target.value)
																		}
																	/>
																)}
																{i !== 0 && i !== 1 && Number(v.price)}
															</td>
															<td className="px-6 py-4">{v.price2}</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								) : null}
							</div>
						)}
					</div>

					<div className="flex gap-3 mt-8 justify-end w-full">
						<Button
							btnStyle={{ backgroundColor: 'red' }}
							onClick={() => {
								navigate('/installment-plan')
							}}
						>
							{t('back')}
						</Button>
						<Button
							onClick={checkU_WHSCode}
							isLoading={isMainLoading}
							disabled={!toWarehouse}
						>
							{t('create')}
						</Button>
					</div>
				</div>
			</CreateProductsStyle>
			<>
				<CreateUserModal
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onConfirm={() => {
						succesCreateUserRef.current?.open('Клиент успешно создан')
					}}
				/>
				<CreateIcloudModal
					getRef={(r) => (createIcloud.current = r)}
					// onConfirm={() => {
					// 	succesCreateUserRef.current?.open('Клиент успешно создан')
					// }}
				/>
				<ErrorModal
					getRef={(r) => (errorRef.current = r)}
					onConfirm={() => {
						setIsMainLoading(false)
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						succesCreateUserRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successModalRef.current = r
					}}
					onConfirm={() => {
						const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(viewData))}&DocEntry=${viewData.DocEntry}`
						navigate(url)
						//  navigate('/viewItem', { state: viewData })
					}}
				/>

				<ChangeDate
					getRef={(r) => {
						changeDateRef.current = r
					}}
					onConfirm={(v) => changeDate(v)}
				/>
				<UpdateUser
					getRef={(ref) => (updateUserRef.current = ref)}
					// onUpdated={() => search(data.currentPage, data.data)}
				/>

				<WarningModal
					getRef={(ref) => {
						infoNotEndedRef.current = ref
					}}
				/>
			</>
		</Layout>
	)
}

export default CreateProducts
